import * as React from "react"
import scrollTo from 'gatsby-plugin-smoothscroll'

import Layout from "../components/layout"
import Seo from "../components/seo"
import PageTop from "../components/pageTop"

import uMotion from '../images/AI.jpg'
import iPad from '../images/iPad.jpg'
import camera from "../images/camera.jpg"


const AI = () => {

	return (
  	<Layout>
    	<Seo title="佐々畜産のAIを用いた畜産" />

      <PageTop classname="AI" title="AIを用いた畜産" />

		  <div id="main" className="AI">
			  <section className="index-section AI">
          <div className="content viewHeight100">
            <div className="centering-container text-center">
              <p className="margin-bottom10 text-justify">昔の「牛飼い」というのは、庭先に数頭だけ飼っており、毎日の餌やりや除糞作業の時に牛達を観察し牛の体調管理を行っていたと思います。
              それが近代の畜産になり多頭化し、1万頭規模のギガファームも珍しくなくなりました。しかしながら、私が思うに牛飼いの究極は「完全なる個体管理」だと思っています。
              それは、1000頭、10000頭以上いても同じです。</p>
              <div className="display-block margin-top30">
                <button type="button" className="material-icons btn-next cp_anime08" onClick={() => scrollTo('#umotion')}>expand_more</button>
              </div>
            </div>
          </div>
        </section>

        <section className="index-section AI" id="umotion">
          <div className="baseBox" id="nMotion">
            <img src={uMotion} alt="uMotion"/>
          </div>
          <div className="content">
            <div className="centering-container">
              <p>完全なる個体管理を可能にするのがこのU-motion（デザミス株式会社）だと思います。佐々畜産では、牛の行動をモニタリングするシステムであるU-motionを活用しています。タバコの箱程度の大きさのセンサーをベルトで首に装着し、牛の個体の行動を24時間モニタリングするシステムです。</p>
              <p>U-motionには加速度センサーと気圧センサーが付いており、それぞれ次のような役割があります。
              <ul>
                <li>加速度センサー：牛が歩き回る動態・静止・反芻などの活動を認識します。</li>
                <li>気圧センサー：方は牛の姿勢を判断し、立っている状態か、座っているかを区別できます。</li>
              </ul>
              </p>
              {/* <div className="button btn-sticky"  onClick={() => scrollTo('#iPad')}>次へ</div> */}
            </div>
          </div>
        </section>

        <section className="index-section AI">
          <div className="baseBox" id="iPad">
            <img src={iPad} alt="iPad screen"/>
          </div>
          <div className="content">
            <div className="centering-container">
              <p>この牛達の情報は、携帯やパソコンでも見ることが出来ますので、農場の外にいても牛達の行動を確認することが出来ます。さらに、各個体の24時間のデータを集積し、人工知能により、母牛の発情や疾病などの異常を自動で検知し、アラート機能で知らせてくれるシステムがありとても重宝しています。</p>
              {/* <Link to="/AI" className="button btn-sticky">上へ</Link> */}
              {/* <div className="button btn-sticky"  onClick={() => scrollTo('#banner')}>上へ</div> */}
            </div>
          </div>
        </section>

        <section className="index-section AI">
          <div className="baseBox" id="iPad">
            <img src={camera} alt="camera"/>
          </div>
          <div className="content">
            <div className="centering-container">
              <p>当農場ではU-motion以外にも「監視カメラ」やお産を知らせてくれる「牛温恵」などを農場に装備しており、分娩も含めた牛達の異常を迅速に察知できるように心掛けています。</p>
              {/* <Link to="/AI" className="button btn-sticky">上へ</Link> */}
              {/* <div className="button btn-sticky"  onClick={() => scrollTo('#banner')}>上へ</div> */}
            </div>
          </div>
        </section>
		  </div>
  	</Layout>
	)
}

export default AI
